<template>
    <div class="adminindex">
        <div class="index-data">
            <div class="dan">
                <div class="text">鲁班昨日新增商品</div>
                <div class="num">{{indexData.luban_count}}</div>
                <!-- <div class="img"><img :src="item.imgurl" alt=""></div> -->
            </div>
            <!-- <div class="dan">
                <div class="text">小店昨日新增商品</div>
                <div class="num">{{indexData.douyin_count}}</div>
            </div>
            <div class="dan">
                <div class="text">快手昨日新增商品</div>
                <div class="num">{{indexData.kuaishou_count}}</div>
            </div> -->
            <div class="dan">
                <div class="text">店铺昨日新增搜索</div>
                <div class="num">{{indexData.store_search}}</div>
                <!-- <div class="img"><img :src="item.imgurl" alt=""></div> -->
            </div>
            <div class="dan">
                <div class="text">昨日注册用户</div>
                <div class="num">{{indexData.user_yesterday_reg_count}}</div>
                <!-- <div class="img"><img :src="item.imgurl" alt=""></div> -->
            </div>
            <div class="dan">
                <div class="text">鲁班总店铺搜索</div>
                <div class="num">{{indexData.store_count}}</div>
                <!-- <div class="img"><img :src="item.imgurl" alt=""></div> -->
            </div>
            <div class="dan">
                <div class="text">昨日提审数量</div>
                <div class="num">{{indexData.ocea_repair_order_success}}</div>
                <!-- <div class="img"><img :src="item.imgurl" alt=""></div> -->
            </div>
            <!-- <div class="dan">
                <div class="text">提审失败数量</div>
                <div class="num">{{indexData.ocea_repair_order_error}}</div>
            </div> -->
            <div class="dan">
                <div class="text">昨日鲁店销量查询</div>
                <div class="num">{{indexDataWeblog.luban_sales}}</div>
            </div>
            <div class="dan">
                <div class="text">二郎查会员广告页</div>
                <div class="num">{{indexDataWeblog.vip_advertising}}</div>
            </div>
            <div class="dan" @click="ongetNotLoginUserIpCount()">
                <div class="text">未登录用户IP数</div>
                <div class="num">{{indexData.not_login_user_ip_count}}</div>
            </div>
            <div class="dan" @click="ongetAllIpCount()">
                <div class="text">昨日IP访问数</div>
                <div class="num">{{indexData.all_ip_count}}</div>
            </div>
        </div>
        <div class="index-log">
            <div class="thelog">
                <div class="adminimg"><img src="https://2lc-oss.erlangcha.com/usergongju/adminindex8.jpg" alt=""></div>
                <div class="adminlog">
                    <div class="title">登录日志</div>
                    <el-table :data="tableData" style="width: 100%">
                        <el-table-column prop="id" label="ID" width="80"></el-table-column>
                        <el-table-column prop="admin_acc" label="用户名" min-width="100"></el-table-column>
                        <el-table-column prop="admin_pwd" label="密码"></el-table-column>
                        <el-table-column prop="login_ip" label="IP"></el-table-column>
                        <el-table-column prop="login_time" label="时间"></el-table-column>
                        <el-table-column prop="login_status" label="状态" min-width="100">
                            <template slot-scope="scope">
                                <span :style="'color:' + (scope.row.login_status == 2 ? '#FFA92A' : '#666')">{{scope.row.login_status == 2 ? '登录异常' : '登录成功'}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
                </div>
            </div>
            <div class="onlinehot">
                <el-empty :image-size="200"></el-empty>
            </div>
        </div>
        <el-dialog title="未登录用户IP数" :visible.sync="loginUserDialog" append-to-body custom-class='class-dialog' width="700px" :close-on-click-modal='false' :close-on-press-escape='false'>
            <div class="dialog-inside">
                <div class="sunbox">
                    <div class="sunbox-text"><span class="text-xing">*</span>时间选择</div>
                    <div class="sunbox-content time">
                        <el-date-picker @change="ongetNotLoginUserIpCount()" v-model="loginParam_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                    </div>
                </div>
            </div>
            <el-table :data="loginUserList" style="width: 100%;padding-bottom:20px">
                <el-table-column prop="date" label="日期" ></el-table-column>
                <el-table-column prop="count" label="ip数" ></el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="昨日IP访问数" :visible.sync="allIpDialog" append-to-body custom-class='class-dialog' width="700px" :close-on-click-modal='false' :close-on-press-escape='false'>
            <div class="dialog-inside">
                <div class="sunbox">
                    <div class="sunbox-text"><span class="text-xing">*</span>时间选择</div>
                    <div class="sunbox-content time">
                        <el-date-picker @change="ongetAllIpCount()" v-model="allIpParam_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                    </div>
                </div>
            </div>
            <el-table :data="allIpList" style="width: 100%;padding-bottom:20px">
                <el-table-column prop="date" label="日期" ></el-table-column>
                <el-table-column prop="count" label="ip数" ></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            indexData:[],
            indexDataWeblog:{},
            // indexData: [
            //     {
            //         text: "鲁班昨日新增商品",
            //         num: "",
            //         // imgurl: "https://2lc-oss.erlangcha.com/usergongju/adminindex1.png",
            //     },
            //     {
            //         text: "小店昨日新增商品",
            //         num: "",
            //         // imgurl: "https://2lc-oss.erlangcha.com/usergongju/adminindex2.png",
            //     },
            //     {
            //         text: "快手昨日新增商品",
            //         num: "",
            //         // imgurl: "https://2lc-oss.erlangcha.com/usergongju/adminindex3.png",
            //     },
            //     {
            //         text: "店铺昨日新增搜索",
            //         num: "",
            //         // imgurl: "https://2lc-oss.erlangcha.com/usergongju/adminindex4.png",
            //     },
            //     {
            //         text: "鲁班总店铺搜索",
            //         num: "",
            //         // imgurl: "https://2lc-oss.erlangcha.com/usergongju/adminindex5.png",
            //     },
            //     {
            //         text: "昨日提审数量",
            //         num: "",
            //         // imgurl: "https://2lc-oss.erlangcha.com/usergongju/adminindex6.png",
            //     },
            //     {
            //         text: "提审失败数量",
            //         num: "",
            //         // imgurl: "https://2lc-oss.erlangcha.com/usergongju/adminindex7.png",
            //     },
            // ],
            tableData: [],
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            page: 1,
            loginUserDialog:false,//未登录用户弹窗
            loginParam_time:'',
            loginUserList:[],
            allIpDialog:false,//昨日ip访问数弹窗
            allIpParam_time:'',
            allIpList:[],
        };
    },

    mounted() {
        this.show();
        this.adminLoginLog();
        this.ongetWeblogList()
        this.ongetWeblogList2()
    },
    methods: {
        // 未登录用户弹窗数据
        ongetNotLoginUserIpCount(){
            this.loginUserDialog = true
            var param = {
                start_time:this.loginParam_time ? this.loginParam_time[0] : "",
                end_time:this.loginParam_time ? this.loginParam_time[1] : ""
            };
            this.$service.get(this.$api.getNotLoginUserIpCount, param, (res) => {
                if (res.code == "200") {
                    this.loginUserList = res.data
                }
            });
        },
        // 未登录用户弹窗数据
        ongetAllIpCount(){
            this.allIpDialog = true
            var param = {
                start_time:this.allIpParam_time ? this.allIpParam_time[0] : "",
                end_time:this.allIpParam_time ? this.allIpParam_time[1] : ""
            };
            this.$service.get(this.$api.getAllIpCount, param, (res) => {
                if (res.code == "200") {
                    this.allIpList = res.data
                }
            });
        },
        //翻页
        DialogCurrentChange(val) {
            // console.log(val);
        },
        show() {
            var token = JSON.parse(localStorage.getItem("TZ-USER")).admin_token;
            var param = {
                token: token,
            };
            this.$service.get(this.$api.show, param, (res) => {
                var data = res.data;
                // console.log(data);
                if (res.code == "200") {
                    this.indexData = data
                    // this.indexData[0].num = data.luban_count;
                    // this.indexData[1].num = data.douyin_count;
                    // this.indexData[2].num = data.kuaishou_count;
                    // this.indexData[3].num = data.store_search;
                    // this.indexData[4].num = data.store_count;
                    // this.indexData[5].num = data.ocea_repair_order_success;
                    // this.indexData[6].num = data.ocea_repair_order_error;
                }
            });
        },
        ongetWeblogList(){
            var param = {
                date:this.timeBefore(1),
                uri_name:"鲁店销量数据查询"
            }
            this.$service.get(this.$api.getWeblogList, param, (res) => {
                if (res.code == "200") {
                    this.indexDataWeblog.luban_sales = res.data.data[0].weblog_count
                }
            });
        },
        ongetWeblogList2(){
            var param = {
                date:this.timeBefore(1),
                uri_name:"二郎查会员广告页面"
            }
            this.$service.get(this.$api.getWeblogList, param, (res) => {
                if (res.code == "200") {
                    this.indexDataWeblog.vip_advertising = res.data.data[0].weblog_count
                }
            });
        },
        adminLoginLog() {
            var token = JSON.parse(localStorage.getItem("TZ-USER")).admin_token;
            var param = {
                token: token,
            };
            this.$service.get(this.$api.adminLoginLog, param, (res) => {
                if (res.code == "200") {
                    this.tableData = res.data.data;
                }
            });
        },
        // 分页
        DialogCurrentChange(val) {
            var token = JSON.parse(localStorage.getItem("TZ-USER")).admin_token;
            this.tableData = [];
            var param = {
                token: token,
                page: val,
            };
            this.$service.get(this.$api.adminLoginLog, param, (res) => {
                if (res.code == "200") {
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
        timeBefore(time) {
            var day1 = new Date();
            day1.setDate(day1.getDate()-time);
            return day1.getFullYear()+"-" + (day1.getMonth()+1) + "-" + day1.getDate();
            // var date = new Date();
            // var y = date.getFullYear();
            // var m = date.getMonth() + 1;
            // var d = date.getDate() - time;
            // console.log(y,m,d,11111111111)
            // if (d < 0) {
            //     m = date.getMonth();
            //     var d1 = new Date(y, m, 0);
            //     var d2 = d1.getDate();
            //     return y + '-' + (m < 10 ? ('0' + m) : m) + '-' + (d2 + d);
            // }
            // else {
            //     return y + '-' + (m < 10 ? ('0' + m) : m) + '-' + (d < 10 ? ('0' + d) : d);
            // }
        },
    },

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.adminindex {
    background: #f2f7fa;
    min-height: 100vh;
    .index-data {
        display: flex;
        justify-content: space-between;
        .dan {
            padding: 25px 20px;
            background: #fff;
            border-radius: 4px;
            width: calc((100% / 9) - 15px);
            .text {
                font-size: 16px;
                font-weight: 500;
                color: #666;
                text-align: center;
            }
            .num {
                margin-top: 20px;
                font-size: 30px;
                font-weight: 500;
                color: #333;
                text-align: center;
            }
            .img {
                // width: ;
            }
        }
    }
    .index-log {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .thelog {
            flex-grow: 1;
            // width: calc(100% - 490px);
            .adminimg {
                border-radius: 4px;
                overflow: hidden;
                text-align: center;
                background: #0556ac;
                flex-shrink: 0;
                img {
                    max-width: 1025px;
                }
            }
            .adminlog {
                margin-top: 20px;
                background: #fff;
                .title {
                    padding: 20px;
                    font-size: 16px;
                    color: #333;
                    font-weight: 500;
                }
                .el-table {
                    border-radius: 0;
                }
            }
        }
        .onlinehot {
            border-radius: 4px;
            background: #fff;
            margin-left: 20px;
            width: 470px;
            flex-shrink: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
<style lang="scss">
.adminindex {
    .index-log {
        .adminlog {
            .el-table td,
            .el-table th.is-leaf {
                border: none;
            }
        }
    }
}
.sunbox-content{
    &.time{
        .el-date-editor .el-range__icon,.el-date-editor .el-range-separator{
            line-height: 28px;
        }
        .el-date-editor .el-range-separator{
            width: 10%;
        }
    }
}
</style>